<template>
	<div :style="bg" >
		<Header title="非洲矿业基金"></Header>
		<div class="column-center-content main-content" style="margin-top: 56px;">
			<div style="background-color: #FFFFFF; height: 180px; width: 100%; text-align: center;">
				<img height="120px" style="margin-top: 10px;" src="../../assets/ammf.jpg" />
			</div>
			
			<div style="display: flex; flex-direction: row; justify-content: space-between; margin-top: -40px; border-radius: 10px; background-color: #FFFFFF; width: 90%; padding: 16px; box-shadow: 2px 2px 10px #C3C3C3;">
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/up.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">年化收益12%</label>
				</div>
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/money.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">不定期分红</label>
				</div>
				<div style="display: flex; flex-direction: column; justify-content: center; align-items:center; text-align: center;">
					<img width="50px" src="../../assets/money/company.png" />
					<label style="font-size: 14px; color: #409EFF; font-weight: bold; margin-top: 10px;">推广赚收益</label>
				</div>
			</div>
			
			<div style="margin-top: 10px; border-radius: 10px; background-color: #FFFFFF; width: 90%; padding: 20px 16px 5px 16px;">
				<label style="font-size: 16px; font-weight: bold; margin-top: 5px;">期限</label>
				<div class="form-content" style="margin-top: 5px; margin-bottom: 5px; width: 100%; display: flex; flex-direction: row;">
					<mt-range
						style="width: 90%; margin-top: 5px;"
						v-model="rangeValue"
						disabled
						:min="1"
						:max="5"
						:step="2"
						:bar-height="1">
					</mt-range>
					<label style="line-height: 40px; width: 50px; text-align: right;">{{rangeValue}}  年</label>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">金额</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field placeholder="请输入购买金额" color="success" style="width: 100%; padding-top: 0px;" v-model="amount" type="number"></v-text-field>
					<label style="line-height: 40px; width: 50px; text-align: right;">USDT</label>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">真实姓名</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field placeholder="请输入真实姓名" color="success" style="width: 100%; padding-top: 0px;" v-model="name"></v-text-field>
				</div>
				
				<label style="font-size: 16px; font-weight: bold;">联系地址</label>
				<div class="form-content" style="margin-top: 0px; width: 100%; display: flex; flex-direction: row;">
					<v-text-field placeholder="请输入联系地址" color="success" style="width: 100%; padding-top: 0px;" v-model="address"></v-text-field>
				</div>
			</div>
			
			<div style="margin-top: 10px; border-radius: 10px; background-color: #FFFFFF; width: 90%; padding: 20px 16px 5px 16px; ">
				<label style="font-size: 16px; font-weight: bold; ">签名</label>
				<vue-esign  ref="esign" :height="300" style="margin-top: 10px; margin-bottom: 10px; border: 1px solid #C3C3C3;"/>
				<div style="width: 100%; text-align: right;">
					<label @click="resetEsign" style="color: #409EFF;">重新签名</label>
				</div>
			</div>
			
			<div class="btn-content" @click="buyAction" style="margin-bottom: 20px; margin-top: 30px;">
				<v-btn rounded block style="background-color: #009245; color: white; height: 40px;">
					买入
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../components/NavHeader.vue'
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				rangeValue: 3,
				amount: 0,
				address: '',
				esignImg: '',
				name: ''
			}
		},
		methods: {
			resetEsign() {
				this.$refs.esign.reset()
			},
			
			buyAction() {
				
				let amount = this.amount
				let address = this.address
				let name = this.name
				
				if(null == amount || '' == amount) {
					Toast({
						message: this.$i18n.t("money.amount_error"),
						position: 'bottom'
					});
					return;
				}
				if(amount <= 0) {
					Toast({
						message: this.$i18n.t("money.amount_error"),
						position: 'bottom'
					});
					return;
				}
				if(amount < 20) {
					Toast({
						message: this.$i18n.t("money.amount_min"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == name || '' == name) {
					Toast({
						message: this.$i18n.t("money.name_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == address || '' == address) {
					Toast({
						message: this.$i18n.t("money.address_error"),
						position: 'bottom'
					});
					return;
				}
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				
				this.$refs.esign.generate().then(res=>{
					this.esignImg = res;
					let params = {
						amount: this.amount,
						days: this.rangeValue * 365,
						sign: this.esignImg,
						address: this.address,
						name: this.name
					}
					this.$request.buyFund(
						params
					).then(()=>{
						Indicator.close();
						Toast({
							message: this.$i18n.t("tips.success"),
							position: 'bottom'
						});
					});
				}).catch(() => {
					Indicator.close();
					Toast({
						message: this.$i18n.t("money.esign_error"),
						position: 'bottom'
					});
					
				});
				
			}
		}
	}
</script>

<style>
</style>
